import React, { ReactElement,useState, useRef, useEffect } from 'react'
import './Toast.css'
import { CheckIcon } from '../Icons/CheckIcon'
import { ErrorIcon } from '../Icons/ErrorIcon'
import { WarningIcon } from '../Icons/WarningIcon'
import { CloseIcon } from '../Icons/CloseIcon'
import { useDispatch } from 'react-redux'
import { popMessage, removeMessage } from '../Notifier/StatusQueueSlice'

export enum toastType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export enum toastDuration {
  SHORT = 'SHORT', // dismiss after 2 seconds
  LONG = 'LONG',  // dismiss after 3.5 seconds
}

export enum toastPosition {
  topLeft = 'top-left',
  topRight = 'top-right',
  topCenter = 'top-center',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
  bottomCenter = 'bottom-center',
  notifier = 'notifier', // use the useErrorStatus hook
}

interface ToastTypeInterface {
  toastPosition: toastPosition
  toastMessage: string
  toastType: toastType
  identifier?: string
  onClickCallback?: () => void
  duration?: toastDuration // new property for duration
}

const Toast = ({
  toastMessage,
  toastPosition,
  toastType,
  onClickCallback,
  identifier,
  // duration = toastDuration.SHORT,
}: ToastTypeInterface) => {
  const toastClasses: string =
    'absolute flex z-[9999] items-center w-full p-4 mb-4 text-gray-500 h-auto\n' +
    '        bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800'

  const [showToast, setShowToast] = useState(true);
  const [idField, setIdField] = useState<string>(Math.round(Math.random()*100000000).toString(16))
  const dispatch = useDispatch()

  const idProp = identifier !== undefined ? identifier : ''
  const toastRef = useRef<HTMLDivElement>(null)

  const renderIcon = (): ReactElement => {
    return toastType === 'error'
      ? ErrorIcon
      : toastType === 'warning'
      ? WarningIcon
      : CheckIcon
  }
  
  const handleCloseClick = () => {
    setShowToast(false);
    if(toastRef.current !== null){
      toastRef.current.classList.add('transition')
      toastRef.current.classList.add('hidden') 
    }
    if (idProp !== undefined) {
      dispatch(removeMessage(idProp))
    }
    if (onClickCallback) {
      onClickCallback();
    }
  };
  const getToastClasses = (): string => {
    const toastPositions = {
      'top-left': 'absolute top-5 left-5',
      'top-right': 'absolute top-20 right-5',
      'bottom-left': 'absolute right-5 bottom-5',
      'bottom-right': 'absolute bottom-5 left-5',
      'top-center': 'absolute top-5',
      'bottom-center': 'absolute bottom-5',
      'notifier': 'inline-block h-16 relative', 
    }

    return `${toastClasses} ${toastPositions[toastPosition]} flex flex-row`
  }

  return (
    <>
     {showToast &&
      <div ref={toastRef} id={"id" + idField} className={getToastClasses()} role="alert">
        {renderIcon()}
        <div className='ml-3 flex flex-col text-left'>
          <div className='font-bold text-[#2c221e] text-md'>{
            toastType === 'success' ? 'Success' : toastType === 'error' ? 'Error' : toastType === 'warning' ? 'Warning' : 'Info'
            }</div>
          <div className="text-xs text-[#2c221e]">{toastMessage}</div>
        </div>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-white text-black
          hover:text-black rounded-lg focus:ring-2 focus:ring-gray-300
          p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500
          dark:hover:text-white dark:bg-black dark:hover:bg-gray-700"
          data-dismiss-target={"#id"+idField}
          aria-label="Close"
          onClick={handleCloseClick}
          data-identifier={idProp}
        >
          {CloseIcon}
        </button>
      </div>
      }
    </>
  )
}

export default Toast
