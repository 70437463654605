export enum WetDogWeatherLayersEnum {
  MRMS = 0,
  XWEATHER_RADAR = 1,
  RADAR = 2,
  CLOUDCEILING = 3,
  CLOUDCOVER = 4,
  TEMPERATURE = 5,
  WINDUV = 6,
  WIND80M = 7,
  WINDGUST = 8,
  CURRENT_CLOUDCEILING = 9,
  CURRENT_CLOUDCOVER = 10,
  CURRENT_TEMPERATURE = 11,
  CURRENT_WINDUV = 12,
  CURRENT_WIND80M = 13,
  CURRENT_WINDGUST = 14,
}
