export const MenuLabels = {
    SURFACEOBS: 'surface-observations-menu',
    METARS: 'aviation-metar',
    TAFS: 'aviation-taf',
    PIREPS: 'aviation-pirep',
    SIGMETS: 'aviation-sigmet',
    GAIRMETS: 'aviation-airmet',
    ICING: 'aviation-wafs-icing',
    TURBULENCE: 'aviation-wafs-turbulence',
    FORECAST_CEILING: 'forecast-aviation-ceiling',
    FORECAST_VISIBILITY: 'forecast-aviation-visibility',
    FORECAST_TEMPERATURE: 'forecast-aviation-temperature',
    FORECAST_SURFACE_WINDS: 'forecast-aviation-surface-winds',
    FORECAST_WINDS_80M: 'forecast-aviation-winds-80m',
    FORECAST_WIND_GUST: 'forecast-aviation-wind-gust',
    TEMPERATURE_BR : 'aviation-temperature-br',
    CEILING_BR: 'aviation-ceiling-br',
    VISIBILITY_BR: 'aviation-visibility-br',
    WIND_GUST_BR: 'aviation-windgust-br',
    WINDS_80M_BR: 'aviation-winds-80m-br',
    SURFACE_WINDS_BR: 'aviation-surface-winds-br',
    CEILING_MIDDLE_EAST: 'aviation-ceiling-middle-east',
    VISIBILITY_MIDDLE_EAST: 'aviation-visibility-middle-east',
    TEMPERATURE_MIDDLE_EAST: 'aviation-temperature-middle-east',
    SURFACE_WINDS_MIDDLE_EAST: 'aviation-surface-winds-middle-east',
    WINDS_80M_MIDDLE_EAST: 'aviation-winds-80m-middle-east',
    WIND_GUST_MIDDLE_EAST: 'aviation-wind-gust-middle-east',
    CEILING_CENTRAL_EUROPE: 'aviation-ceiling-ce',
    VISIBILITY_CENTRAL_EUROPE: 'aviation-visibility-ce',
    TEMPERATURE_CENTRAL_EUROPE: 'aviation-temperature-ce',
    SURFACE_WINDS_CENTRAL_EUROPE: 'aviation-surface-winds-ce',
    WINDS_80M_CENTRAL_EUROPE: 'aviation-winds-80m-ce',
    WIND_GUST_CENTRAL_EUROPE: 'aviation-wind-gust-ce',
    CEILING_WESTERN_EUROPE: 'aviation-ceiling-uk',
    VISIBILITY_WESTERN_EUROPE: 'aviation-visibility-uk',
    TEMPERATURE_WESTERN_EUROPE: 'aviation-temperature-uk',
    SURFACE_WINDS_WESTERN_EUROPE: 'aviation-surface-winds-uk',
    WINDS_80M_WESTERN_EUROPE: 'aviation-winds-80m-uk',
    WIND_GUST_WESTERN_EUROPE: 'aviation-wind-gust-uk',
    CEILING_CANADA: 'aviation-ceiling-ca',
    VISIBILITY_CANADA: 'aviation-visibility-ca',
    TEMPERATURE_CANADA: 'aviation-temperature-ca',
    SURFACE_WINDS_CANADA: 'aviation-surface-winds-ca',
    WINDS_80M_CANADA: 'aviation-winds-80m-ca',
    WIND_GUST_CANADA: 'aviation-windgust-ca',
    CAMERA_NETWORK: 'camera-network',
    VERTICAL_PROFILE: 'vertical-profile',
    MISSIONCAST: 'decision-missioncast',
    ROUTECAST: 'decision-routecast',
    MRMS_RADAR: 'observations-mrms-radar',
    MYRADAR: 'observations-myradar',
    LIGHTNING: 'observations-lightning',
    ALERTS_CREATE: 'alerts-create',
    ALERTS_MENU: 'alerts-menu',
    ADD_LOCATION: 'add-location',
    LOCATION: 'location-menu',
    MAPS_SATELLITE: 'maps-satellite',
    MAPS_ROADS: 'maps-road',
    MAPS_SECTIONAL: 'maps-sectional',
    CEILING: 'aviation-ceiling',
    VISIBILITY: 'aviation-visibility',
    TEMPERATURE: 'aviation-temperature',
    SURFACE_WINDS: 'aviation-surface-winds',
    WINDS_80M: 'aviation-winds-80m',
    WIND_GUST: 'aviation-wind-gust',
    EVENT_LIST: 'Events List',
    ALERTS: 'Alerts', 
    OBSERVATIONS: 'observations-menu',
    XWEATHER_RADAR: 'observations-xweather-radar',
}