import { useDispatch, useSelector } from 'react-redux'
import LocationPopup from '../Components/Users/UserLocation/LocationPopup'
import 'leaflet-draw/dist/leaflet.draw.css'
import { useMap, useMapEvents } from 'react-leaflet'
import { ReactElement, useEffect, useState } from 'react'
import store, { RootState } from '../store'
import AlertDraw from '../Components/Alerting/AlertSetting/AlertDraw'
import { MissionCAST } from '../Components/MissionCAST/MissionCAST'

import { latLng } from 'leaflet'
import getActiveEvents from '../Components/Alerting/AlertingSlice/getActiveAlerts'
import {
  editData,
  finalEventsToDisplay,
  getAllEvents,
  responseOk,
  setAlertingNotification,
  storingEventsToDisplay,
} from '../Components/Alerting/AlertingSlice/AlertingSlice'
import useErrorStatus from '../hooks/UseErrorStatus'
import { hideAddLocationPopup } from '../Components/Users/UserSlice'
import { setSelectedItemIds } from '../menus/SideNavigation/SideNavigationSlice'
import { fetchSavedProfiles } from '../Components/SavedViews/savedViews'
import { setSavedProfile } from '../Components/SavedViews/savedViewsSlice'
import { ErrorTimeouts } from '../constants/ErrorTimeouts'

interface MapChildComponentProps {
  children?: React.ReactNode
  selectedSideNav: string
}

const MapChildComponent: React.FC<MapChildComponentProps> = ({
  children,
  selectedSideNav,
}) => {
  // const [currentLoc, setCurrentLoc] = useState(null)
  const location = useSelector((state: RootState) => state.user.defaultLocation)
  const [content, setContent] = useState<ReactElement>(<></>)
  const dispatch = useDispatch()
  const errorStatus = useErrorStatus()

  const showAddLocationPopup = useSelector(
    (state: RootState) => state.user.addNewLocation
  )
  const selectedItemIds = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )

  const showMissionCAST = useSelector(
    (state) => state.missioncast.showMissionCAST
  )
  const showAlertSETTING = useSelector((state) => state.alert.showAlertSETTING)
  const hideAlert = useSelector((state) => state.alert.hideAlertSETTING)
  const response = useSelector((state) => state.alert.responseOk)
  const eventCount = useSelector((state) => state.alert.eventCount)
  const totalLocations = useSelector(
    (state: RootState) => state.user.totalLocations
  )
  const locations = useSelector((state: RootState) => state.user.locations)
  const sideNavExpanded = useSelector(
    (state: RootState) => state.sideNav.expanded
  )
  const leafletMap = useMap()
  const selectedMenuItem = useSelector((state: RootState) => state.sideNav.selectedMenuItem)

  useEffect(() => {
    if (location && location.latitude && location.longitude) {
      leafletMap.flyTo(
        latLng(parseFloat(location.latitude), parseFloat(location.longitude))
      )
    }
  }, [location])
  useEffect(() => {
    // if the side nav is expanded, the map should be re-rendered
    // to show the area on the right previously obscured by the side nav
    map.invalidateSize()
  }, [sideNavExpanded])

  useEffect(() => {
    if (
      !selectedItemIds.includes('location-menu') &&
      selectedItemIds.includes('add-location')
    ) {
      dispatch(hideAddLocationPopup())
      let tempselectedItemIds = selectedItemIds
      tempselectedItemIds = tempselectedItemIds.includes('add-location')
        ? tempselectedItemIds.filter((item) => item !== 'add-location')
        : [...tempselectedItemIds, 'add-location']
      dispatch(setSelectedItemIds(tempselectedItemIds))
    }
    
  },[selectedItemIds])
  useEffect(()=>{
    const run = async () => {
      let savedprofile = await fetchSavedProfiles()
      dispatch(setSavedProfile(savedprofile))
    }
    run()
    },[dispatch])

  useEffect(() => {
    if (!showAddLocationPopup) {
      setContent(<></>)
    }
  }, [showAddLocationPopup])

  // useEffect(() => {
  //   console.log('Selected menu item:', selectedMenuItem)
  // },[selectedMenuItem])

  const map = useMapEvents({
    click: (event) => {
      if (event.originalEvent?.srcElement?.tagName == 'INPUT') {
        return
      }
      if (
        showAddLocationPopup &&
        selectedItemIds.includes('add-location') &&
        totalLocations &&
        locations?.length >= totalLocations
      ) {
        errorStatus.addMessage(
          'You have exceeded the number of locations allowed in your plan. Please try deleting some locations before you add a new location',
          400,
          'error',
          false,
          ErrorTimeouts.ADD_LOCATION
        )
        const newSelection = [...store.getState().sideNav.selectedItemIds]
        if (newSelection.includes('add-location')) {
          newSelection.splice(newSelection.indexOf('add-location'), 1)
          dispatch(setSelectedItemIds(newSelection))
        }
      } else if (
        showAddLocationPopup &&
        selectedItemIds.includes('add-location') &&
        totalLocations &&
        totalLocations >= locations?.length
      ) {
        setContent(<LocationPopup position={event.latlng} />)
        map.flyTo(event.latlng)
      } else if (showAddLocationPopup && locations?.length === 0) {
        setContent(<LocationPopup position={event.latlng} />)
        map.flyTo(event.latlng)
      }
    },
  })
  map.attributionControl.addAttribution('Powered by TruWeather Solutions')
  useEffect(() => {
    ;(async () => {
      const dic = {}
      const EditData = await getAllEvents()
      if (EditData?.length > 0) {
        if (Array.isArray(EditData)) {
          EditData?.forEach((element) => {
            const eventName = element?.eventName
            if (eventName) {
              dic[eventName] = element
            }
          })
        }
        dispatch(storingEventsToDisplay(dic))
        dispatch(editData(EditData))
      }

      let result;
      try {
        result = await getActiveEvents()
      } catch (err) {
        // No need to show error here
        // console.log(err);
      }
      if (result && Array.isArray(result) && result.length > 0) {
        const keysAlert = new Set()
        result.forEach((element) => {
          const triggerEvent = element?.properties?.triggerEvent
          if (triggerEvent) {
            keysAlert.add(triggerEvent)
          }
        })
        const keysToExclude = [...keysAlert]
        const filteredObject = {}
        for (const key in dic) {
          if (keysToExclude.includes(key)) {
            continue
          }
          filteredObject[key] = dic[key]
        }
        dispatch(setAlertingNotification(result))
        dispatch(finalEventsToDisplay(filteredObject))
      } else {
        dispatch(finalEventsToDisplay(dic))
      }
    })()
  }, [dispatch])

  useEffect(() => {
    if (showMissionCAST) {
      setContent(<MissionCAST />)
    } else {
      leafletMap.closePopup()
      setContent(<></>)
    }
  }, [showMissionCAST])
  const defaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  useEffect(() => {
    if (
      selectedItemIds.includes('alerts-create') &&
      eventCount < 3 &&
      !store.getState().sideNav.menuItems[0].subMenu[1].disabled
    ) {
      setContent(<AlertDraw defaultLocation={defaultLocation} />)
    } else {
      setContent(<></>)
    }
  }, [showAlertSETTING, hideAlert, selectedItemIds])

  useEffect(() => {
    if (response.valid) {
      errorStatus.addMessage(
        response.msg,
        response.code,
        response.code == 200 ? 'success' : 'error',
        false,
        ErrorTimeouts.GENERAL
      )
      dispatch(responseOk({ valid: false }))
    }
  }, [response])

  return (
    <>
      {children}
      {content}
    </>
  )
}
export default MapChildComponent
