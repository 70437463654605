// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import marker from '../../assets/location.svg'
import signpost from '../../assets/signpost.svg'
import aviation from '../../assets/aviationweather.svg'
import maps from '../../assets/maps.svg'
import forecaster from '../../assets/call.svg'
import addLocation from '../../assets/add-location.svg'
import alert from '../../assets/alert.svg'
import radar from '../../assets/radar.svg'
import observation from '../../assets/observation.svg'
import areaweather from '../../assets/areaweather.svg'
import lightning from '../../assets/lightningicon.svg'
import { ReactNode } from 'react'

/**
 * PLEASE READ
 * To add a menu item programatically, use the appendMenuItem action or the
 * prependMenuItem action.
 *
 * const item = {
 *   id: "an-id-that-will-be-used-for-this-action", // Required.
 *   label: "My Menu Item", // optional. ( for top-most menu item in heirarchy )
 *   link: 'https://www.google.com', //optional.
 *   icon: bigIcon, // optional.
 *   callback: verySpecialCallback({name: loc_name, lat: loc_lat, lng: loc_lng}), // optional. Use any extraData you need.
 *   multiSelect: false, // optional.
 *   subMenu: [], // required.
 * }
 * dispatch(appendMenuItem(item, parentMenuId))
 */


export interface IMenuItem {
  id: string
  label?: string | null
  link?: string | null
  icon?: string | null
  multiSelect?: boolean | null
  active?: boolean | null
  subMenu: Array<IMenuItem>
  callback?: () => void
  specialComponent?: ReactNode
  extraData?: any
  disabled?: boolean
  toggle?: boolean
}

export const sideMenuItems: Array<IMenuItem> = [
  {
    id: 'side-menu-root',
    label: '',
    link: '',
    icon: null,
    active: false,
    multiSelect: false,
    subMenu: [
      {
        id: 'location-menu',
        label: 'Locations',
        link: '/location',
        icon: marker,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'add-location',
            label: 'Add new location',
            link: '/',
            icon: addLocation,
            active: false,
            multiSelect: false,
            subMenu: [],
          },
        ],
      },
      {
        id: 'alerts-menu',
        label: 'Alerts',
        link: '/alerts',
        icon: alert,
        active: false,
        multiSelect: false,
        disabled: false,
        subMenu: [
          {
            id: 'alerts-create',
            label: 'Create an Alert',
            link: '#',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
          },
        ],
      },
      {
        id: 'decision-products-menu',
        label: 'Decision Products',
        link: '/decision-products',
        icon: signpost,
        active: false,
        subMenu: [
          {
            id: 'decision-missioncast',
            label: 'MissionCast',
            link: '#',
            icon: null,
            active: false,
            subMenu: [],
          },
          {
            id: 'decision-routecast',
            label: 'RouteCast',
            link: '#',
            icon: null,
            active: false,
            disabled: false,
            subMenu: [],
          },
        ],
      },
      {
        id: 'radar-menu',
        label: 'Radar',
        link: '/radar',
        icon: radar,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'observations-mrms-radar',
            label: 'Current Radar (US Only)',
            link: '#',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
            disabled: false,
          },
          {
            id: 'observations-xweather-radar',
            label: 'Current Radar (Global)',
            link: '#',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
            disabled: false,
          },
          {
            id: 'observations-myradar',
            label: 'MyRadar NowCAST',
            link: '#',
            icon: null,
            active: false,
            multiSelect: false,
            subMenu: [],
            disabled: false,
          },
        ],
      },
      {
        id: 'observations-lightning',
        label: 'Lightning',
        link: '#',
        icon: lightning,
        active: false,
        multiSelect: true,
        subMenu: [],
      },
      {
        id: 'observations-menu',
        label: 'Observations',
        link: '/observations',
        icon: observation,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'surface-observations-menu',
            label: 'Surface Observations',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [
              // {
              //   id: 'surface-obs-us-menu',
              //   label: 'US',
              //   link: '#',
              //   icon: null,
              //   active: false,
              //   multiSelect: false,
              //   subMenu: [],
              // },
              // {
              //   id: 'surface-obs-ca-menu',
              //   label: 'Canada',
              //   link: '#',
              //   icon: null,
              //   active: false,
              //   multiSelect: false,
              //   subMenu: [],
              // },
              // {
              //   id: 'surface-obs-uk-menu',
              //   label: 'UK',
              //   link: '#',
              //   icon: null,
              //   active: false,
              //   multiSelect: false,
              //   subMenu: [],
              // },
            ],
          },
          {
            id: 'camera-network',
            label: 'Camera Network',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          {
            id: 'vertical-profile',
            label: 'Vertical Profile',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          }
        ],
      },
      {
        id: 'area-weather-menu',
        label: 'Area Weather',
        link: '/areaweather',
        icon: areaweather,
        active: false,
        multiSelect: false,
        subMenu: [
          {
            id: 'weather-menu',
            label: 'Current Area Weather',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [
              {
                id: 'aviation-ceiling',
                label: 'Cloud Ceiling',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'aviation-visibility',
                label: 'Visibility',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'aviation-temperature',
                label: 'Temperature',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'aviation-surface-winds',
                label: 'Surface Winds',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'aviation-winds-80m',
                label: '80m Winds',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'aviation-wind-gust',
                label: 'Wind Gust',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              }
            ],
          },
          {
            id: 'forecast-weather-menu',
            label: 'Forecast Weather',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [
              {
                id: 'forecast-aviation-ceiling',
                label: 'Cloud Ceiling',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-visibility',
                label: 'Visibility',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-temperature',
                label: 'Temperature',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-surface-winds',
                label: 'Surface Winds',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-winds-80m',
                label: '80m Winds',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
              {
                id: 'forecast-aviation-wind-gust',
                label: 'Wind Gust',
                link: '#',
                icon: null,
                active: false,
                multiSelect: false,
                subMenu: [],
              },
            ],
          },
        ],
      },
      {
        id: 'aviation-menu',
        label: 'Aviation Weather',
        link: '/aviation',
        icon: aviation,
        active: false,
        multiSelect: false,
        subMenu: [

              {
                id: 'aviation-pirep',
                label: 'PIREP',
                link: '#',
                icon: null,
                active: false,
                multiSelect: true,
                subMenu: [],
              },
              {
                id: 'aviation-airmet',
                label: 'GAIRMET',
                link: '#',
                icon: null,
                active: false,
                multiSelect: true,
                subMenu: [],
              },
              {
                id: 'aviation-sigmet',
                label: 'SIGMET',
                link: '#',
                icon: null,
                active: false,
                multiSelect: true,
                subMenu: [],
              },
          {
            id: 'aviation-metar',
            label: 'METAR',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          {
            id: 'aviation-taf',
            label: 'TAF',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          {
            id: 'aviation-wafs-icing',
            label: 'WAFS-Icing',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
          {
            id: 'aviation-wafs-turbulence',
            label: 'WAFS-Turbulence',
            link: '#',
            icon: null,
            active: false,
            multiSelect: true,
            subMenu: [],
          },
        ],
      },
      {
        id: 'forecaster-menu',
        label: 'Live Forecaster',
        link: '/forecaster',
        icon: forecaster,
        active: false,
        multiSelect: false,
        disabled: true,
        subMenu: [
          // {
          //   id: 'forecaster-call',
          //   label: 'Call/Video',
          //   link: '#',
          //   icon: null,
          //   active: false,
          //   multiSelect: false,
          //   subMenu: [],
          // },
        ],
      },
      {
        id: 'dark-mode-menu',
        label: 'Dark Mode',
        link: '#',
        icon: null,
        active: false,
        multiSelect: false,
        disabled: false,
        subMenu: []
      },
    ],
  },
]
