import { useEffect, useState } from "react";
// import { getStripeProducts } from "./stripe.service";
import { useAuth0 } from "@auth0/auth0-react";
import { getAccessToken } from "../../utils/auth.util";
// import { loadStripe } from "@stripe/stripe-js";
import { TWS_API_URL } from "../../environment/apis.config";

// const allFeatures = [
//   { key: "surfaceobs", name: "Surface Observations" },
//   { key: "curr_radar", name: "Current Radar" },
//   { key: "myradar", name: "MyRadar™ (Predictive Radar)&trade" },
//   { key: "missioncast", name: "MissionCAST" },
//   { key: "areaobs", name: "Current Area Weather Tiles" },
//   { key: "weatherobs", name: "Current Observations (Best Estimate)" },
//   { key: "aviation", name: "Aviation Weather" },
//   { key: "cameraobs", name: "Camera Network" },
//   { key: "routecast", name: "RouteCAST" },
//   { key: "alerting", name: "Alerting" },
// ];

// const planInformationUrl = "https://truweathersolutions.com/v360-weather/";

const PricingTable = () => {
  const { isAuthenticated, user } = useAuth0();
  // const [plans, setPlans] = useState([]);
  // const [monthlyPlans, setMonthlyPlans] = useState([]);
  // const [yearlyPlans, setYearlyPlans] = useState([]);
  // const [billingCycle, setBillingCycle] = useState("monthly");

  // const { user, logout } = useAuth0();
  
  // useEffect(() => {
  //   getStripeProducts()
  //   .then((response) => {
  //     console.log(response);
  //     const products = response ? response.data : [];

  //     const monthlyPlans = products.filter(plan => plan.interval === "month");
  //     const yearlyPlans = products.filter(plan => plan.interval === "year");

  //     setMonthlyPlans(monthlyPlans);
  //     setYearlyPlans(yearlyPlans);
  //     setPlans(monthlyPlans);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   })
  // }, [])

  // const handleIntervalChange = (interval: string) => {
  //   setBillingCycle(interval);
  //   const newPlans = interval === "monthly" ? monthlyPlans : yearlyPlans;
  //   setPlans(newPlans);
  // }

  // var handleResult = function(result) {
  //   if (result.error) {
  //     // TODO: Show in toast message
  //     console.log(result.error);
  //   }
  // };

  // const getPaymentSession = async (plan) => {
  //   const token = await getAccessToken();

  //   // var host = location.host;
  //   var url = `${TWS_API_URL}/pay?token=${token}`;

  //   // if(host.includes("api.truflitev360")){
  //   //   url = "https://" + location.host + `/pay?${token}`;
  //   // }

  //   console.log(window.location);

  //   return fetch(url, {
  //       method: "POST",
  //       headers: {
  //       "Content-Type": "application/json"
  //       },
  //       referrer: "origin",
  //       body: JSON.stringify({
  //           product: plan?.priceId,
  //           id: user?.subscriptionId,
  //           user: user?.sub,
  //           originLink: window.location.origin,
  //           email: user?.email
  //       })
  //   })
  //   .then(response => response.json())
  //   .then(async (data) => {
  //     const stripe = await loadStripe(data.stripeKey);

  //     if (!stripe) {
  //       console.error("Failed to initialize Stripe");
  //       return;
  //     }

  //     stripe
  //     .redirectToCheckout({
  //       sessionId: data.id
  //     }).then(handleResult);
  //   });
  // }

  // const handleLogout = () => {
  //   logout({
  //     logoutParams: {
  //       returnTo: window.location.origin,
  //     }
  //   });
  // };

  // const openPlanInformation = () => {
  //   window.open(planInformationUrl, "_blank");
  // };

  // return (
  //   <div className="min-h-screen flex items-center justify-center bg-blue-800 p-6">
  //     <button
  //       onClick={openPlanInformation}
  //       className="absolute top-4 left-6 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
  //     >
  //       Pricing info
  //     </button>

  //     <button
  //       onClick={handleLogout}
  //       className="absolute top-4 right-6 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
  //     >
  //       Logout
  //     </button>
  //     <div className="w-full max-w-4xl text-center">
  //       <div className="flex justify-center space-x-4 mb-6">
  //         <button
  //           onClick={() => handleIntervalChange("monthly")}
  //           className={`px-6 py-2 rounded-md ${
  //             billingCycle === "monthly" ? "bg-green-500 text-white" : "bg-blue-700 text-gray-300"
  //           }`}
  //         >
  //           Monthly
  //         </button>
  //         <button
  //           onClick={() => handleIntervalChange("yearly")}
  //           className={`px-6 py-2 rounded-md ${
  //             billingCycle === "yearly" ? "bg-green-500 text-white" : "bg-blue-700 text-gray-300"
  //           }`}
  //         >
  //           Yearly
  //         </button>
  //       </div>

  //       <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
  //         {plans.map((plan) => (
  //           <div key={plan.id} className="bg-blue-700 text-white p-6 rounded-lg shadow-md">

  //             <h2 className="text-2xl font-bold">{plan.name}</h2>
  //             <p className="text-4xl font-bold mt-3">
  //               {plan.price} <span className="text-lg font-light">{plan.period}</span>
  //             </p>

  //             <ul className="mt-4 text-left space-y-2">
  //               {allFeatures.map((feature, index) => (
  //                 <li key={index} className="flex items-center space-x-2">
  //                   {plan.features?.includes(feature.key) ? (
  //                     <span className="text-green-400">✔</span>
  //                   ) : (
  //                     <span className="text-red-500">✖</span>
  //                   )}
  //                   <span>{feature.name}</span>
  //                 </li>
  //               ))}
  //             </ul>

  //             <button 
  //               onClick={() => getPaymentSession(plan)}
  //               className="mt-6 bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-md">
  //               Subscribe
  //             </button>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </div>
  // );

  useEffect(() => {
    const fetchHtml = async () => {
      const token = await getAccessToken();
      window.location.href = `${TWS_API_URL}/payment?token=${token}`;
    };

    if (isAuthenticated) {
      fetchHtml();
    }
  }, [isAuthenticated]);

  return null;
};

export default PricingTable;
