import React, { useEffect } from "react";

export const FeatureFlags = React.createContext({});

export const FeatureFlagsProvider = ({ children }) => {
    const [features, setFeatures] = React.useState({});

    useEffect(() => {
      fetch('https://featureflagsdev.s3.amazonaws.com/feature_flags.json')
      .then(response => response.json())
      .then(data => {
        setFeatures({...data})
      })
    }, [])
  
    return (
      <FeatureFlags.Provider value={{ features }}>
        {children}
      </FeatureFlags.Provider>
    );
  };