import { useEffect, useRef, useState } from 'react'
import Layer from '../../utils/Layers'
import '../../utils/L.RealtimeCanvasLayer.js'
import Terrier from '../../utils/terrier'
import { useMap, useMapEvent, Tooltip } from 'react-leaflet'
import MediaControls from './Media Controls/MediaControls'
import Legend from './Legend/Legend'
import { useDispatch, useSelector } from 'react-redux'
import { setCurLayer } from './WetDogWeatherProductsSlice'
import { WDWSTACK } from '../../environment/apis.config'
import L from 'leaflet'
import useErrorStatus from '../../hooks/UseErrorStatus'
import './WetDogWeatherLayers.css'
import { WetDogWeatherLayersEnum } from '../../interfaces/WetDogWeatherLayers'
// import mask from '../../utils/leaflet.mask.js'
import UsaMask from '../../utils/usa.geojson'

const WetDogWeatherLayers = () => {
  const [legendVisible, setLegendVisible] = useState(true)
  const [layers, setLayers] = useState<Layer[]>([])
  const [level, setLevel] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [animSpeed, setAnimSpeed] = useState(5.0)
  const [timeRange, setTimeRange] = useState([0.0, 0.0])
  const [curTime, setCurTime] = useState(Number.NEGATIVE_INFINITY)
  const [displayedTime, setDisplayedTime] = useState(Number.NEGATIVE_INFINITY)
  const [terrierOvl, setTerrierOvl] = useState(null)
  const [units, _setUnits] = useState('')
  const [canvasLayer] = useState(L.realtimeCanvasLayer())
  const [snapFrame] = useState(true)
  const [radarOverlay, setRadarOverlay] = useState<GeoJSON | null>(null)

  const canvasRef = useRef(null)
  const map = useMap()
  const curLayer = useSelector((state) => state.WetDogWeatherProducts.curLayer)
  const dispatch = useDispatch()
  const errorStatus = useErrorStatus()

  const startUpFunc = () => {
    if (map !== null) {
      Terrier.startLeaflet(WDWSTACK, canvasLayer, (ovl) => {
        terrierReady(ovl)
      })

      canvasLayer.addTo(map)
    }
  }

  useEffect(() => {
    for (let layerId = 0; layerId < layers.length; layerId++) {
      if (layerId != curLayer.layer) {
        layers[layerId].enable(false)
      }
    }
    const now = new Date().getTime() / 1000
    console.log('>>> ', curLayer.layer)
    // Then turn ours on
    if (curLayer.layer >= 0 && curLayer.layer < layers.length) {
      const layer = layers[curLayer.layer]
      layer.enable(true)
      
      //update the time range
      let timeRange = layer.layer.ovl.getTimeRange()
      if (timeRange[0] != timeRange[1]) {
        timeRange[0] = timeRange[0]/1000-now
        timeRange[1] = timeRange[1]/1000-now
      } else {
        timeRange = layer.timeRange
      }
      setTimeRange([now+timeRange[0],now+timeRange[1]])
      if (curTime < timeRange[0]+now) {
        setCurTime(timeRange[0]+now)
      } else if (curTime >= timeRange[1]+now) {
        setCurTime(timeRange[1]+now)
      }


      // And update the units of whatever is being displayed
      _setUnits(layer.units)
      layers[curLayer.layer].getDisplayName() === 'Wind80m'
        ? layers[curLayer.layer].setLevel('80m')
        : layers[curLayer.layer].setLevel(null)
      map.getContainer().classList.remove('leaflet-grab')
      map.getContainer().classList.add('pointer-cursor')

      if (curLayer.layer === WetDogWeatherLayersEnum.XWEATHER_RADAR) {
        var myStyle = {
          fillColor: "#ffffff",
          color: "#fff",
          weight: 1,
          opacity: 1,
          fillOpacity: 1  
      };
        setRadarOverlay(L.geoJSON(UsaMask, {
          style: myStyle
        }).addTo(map))
      }
    } else {
      setCurTime(now)
      setTimeRange([0.0, 0.0])
      _setUnits('')
      setLevel(null)
      map.getContainer().classList.add('leaflet-grab')
      map.getContainer().classList.remove('pointer-cursor')
      if (radarOverlay) {
        map.removeLayer(radarOverlay)
      }
    }
    //this code prevents the browser from crashing
    //check if the click event listener has been set
    //if not set set it 
    // if (curLayer.layer > 0) {
    //   map.addEventListener("click", handleLeafletMapClick)
    // } else {
    //   map.removeEventListener("click")
    // }

  }, [curLayer.layer])

  // useEffect(() => {
  //   if (terrierOvl) {
  //     terrierOvl.setNearestFrame(snapFrame)
  //   }
  // }, [snapFrame])

  // React to curTime changes
  useEffect(() => {
    if (curTime == Number.NEGATIVE_INFINITY) { return }
    if (terrierOvl == undefined) { return }
    terrierOvl.setCurrentTime(curTime)
    setDisplayedTime(terrierOvl.getCurrentTime)
  },[curTime])

  // React to isPlaying changes
  useEffect(() => {
    if (terrierOvl == undefined) { return }
    if (isPlaying) {
      terrierOvl.timePlay({'period': 30.0 / animSpeed, 'pause': 2.0})
    } else {
      terrierOvl.timePause()
      // We were animating, so update our curTime from Terrier
      setCurTime(terrierOvl.getCurrentTime())
      
    }
  },[isPlaying,animSpeed])

  // Add an interval callback to update the curTime periodically when isPlaying is on
  // TODO: Turn this off when we don't need it
  const updatePlayTime = () => {
    if (terrierOvl == undefined) { return }
    if (!isPlaying) { return }
    const newTime = terrierOvl.getCurrentTime()
    // if (curTime != newTime) {
    //   // TODO: Check that we're not creating a slow recursion here
    //   setCurTime(newTime)
    // }
    //the update to setCurTime causes flickering of the layer. Instead update setDisplayedTime directly.
    setDisplayedTime(newTime)
  }
  useEffect(() => {
      const interval = setInterval(() => updatePlayTime(), 100);
      return () => {
        clearInterval(interval);
      };
  }, [terrierOvl,isPlaying,curTime]);

  function  timeRangeForVariable(variable, currMode) {
    switch (variable.dataType) {
      case "temperature":
      case "wind_uv":
      case "velocity":
      case "visibility":
      case "cloudceiling":
        return currMode === 'forecast' ? [0,1*24*60*60,32] : [-1*24*60*60, 0, 32]
    }
    switch (variable.temporalType) {
      // Just observed data
      case "observed":
        return [-4*60*60,0,64]
      // Forecast and both we'll do the same
      case "forecast":
      case "both":
        return [-1*24*60*60,1*24*60*60,32]
    }
  
    return currMode === 'forecast' ? [0,1*24*60*60,32] : [-1*24*60*60, 0, 32]
  }

  function interpForVariable(variable) {
    switch (variable.dataType.toLowerCase()) {
      case "severehailindex":
        return 0
      case "preciptype":
        return 0
      case "none":
        if (variable.name.includes("swath")) {
          return 0
        } else if (variable.name.includes("size")) {
          return 0
        } else if (variable.name.includes("hail")) {
          return 0
        }
        if (variable.name.includes("qpe_ffg")) {
          return 0;
        }
    }
  
    return 1
  }

  // useEffect(() => {
  //   const interval = setInterval(() => updatePlayTime(), 100)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [isPlaying, curTime, terrierOvl])

  const terrierReady = (ovl) => {
    setTerrierOvl(ovl)
    // Clean up any existing layers
    layers.forEach((layer) => {
      layer.enable(false)
    })
    const feetToMeters = 1 / 3.28084
    const cloudColorMap = Terrier.createColorMap(
      [
        0.0 * feetToMeters,
        500.0 * feetToMeters,
        900.0 * feetToMeters,
        1000.0 * feetToMeters,
        3000.0 * feetToMeters,
        4000.0 * feetToMeters,
        5000.0 * feetToMeters,
      ],
      [
        0xff800000, 0xffff0000, 0xffffff00, 0xffff6600, 0xff000080, 0xff003300,
        0xff006400,
      ]
    )

    const statMileToMeters = 1609.34
    const visColorMap = Terrier.createColorMap(
      [
        0,
        1 * statMileToMeters,
        3 * statMileToMeters,
        5 * statMileToMeters,
        7 * statMileToMeters,
        10 * statMileToMeters,
      ],
      [0xff800000, 0xffda0000, 0xffffff00, 0xff00ff00, 0xff003300, 0xff0000]
    )

    const metersToKnots = 1 / 1.94384
    const windColorMap = Terrier.createColorMap(
      [
        0,
        5 * metersToKnots,
        9 * metersToKnots,
        16 * metersToKnots,
        25 * metersToKnots,
        35 * metersToKnots,
        40 * metersToKnots,
      ],
      [
        0xff0000, 0xff00cc05, 0xffecf006, 0xffe11511, 0xff800000, 0xffe111c1,
        0xffffcef7,
      ]
    )
    // Set up the layers we know about and enable the first one
    if (terrierOvl === undefined) {
      return
    }

    // Clean up any existing layers
    // Note: Should make this more intelligent
    layers.forEach( (layer) => {
      layer.enable(false)
    })
    let modes = ['forecast', 'current']
    var newLayers = []
    modes.forEach(mode => {
      // We can have a simple list of variables or absolutely everything
      var variables = Terrier.variablesForStack()
      console.log(variables)
      const toInclude = mode === 'forecast' ? ["reflectivity", "visual radar xweather", "visual radar myradar", "cloud_ceiling", "visibility", "temperature", "wind_uv", "wind_uv_80m", "wind_speed_gust" ]
        : ["cloud_ceiling", "visibility", "temperature", "wind_uv", "wind_uv_80m", "wind_speed_gust" ]
      var newVariables = {}
      toInclude.forEach((variable) => {
        if (variable !== 'wind_uv_80m' && variable in variables) {
          
          newVariables[variable] = variables[variable]
        } else if (variable === 'wind_uv_80m') {
            newVariables[variable] = variables["wind_uv"]
            newVariables[variable].level = ['80m']
        }
      })
      variables = newVariables

      
      for (let varName in variables) {
        let variable = variables[varName]
        var newLayer = null
        // We can filter by region or source, optionally
        var searchParams = {variable: variable.name}
        if (variable.dataType != 'visual') {
          if (varName == 'reflectivity') {
            // Note: We can set radarOnly here to just see radar data
            searchParams['source'] = ["mrms"]
            searchParams['product'] = ["mbr"]
          } else {
            // Filtering down to these three sources will satisfy most people
            searchParams['source'] = ["gfs", "rtma", "hrrr"]
          }
        }
          
        let sources = Terrier.sourcesForVariable(searchParams)
        // This can happen if we're filtering other things
        if (sources.length == 0) {
          continue
        }
        let colorMap = varName === 'cloud_ceiling' ? cloudColorMap : 
          varName === 'wind_uv' || varName === 'wind_uv_80m' || varName === 'wind_speed_gust' ? windColorMap :
          varName === 'visibility' ? visColorMap :
          Terrier.colorMapForVariable(sources[0])
        let timeRange = timeRangeForVariable(sources[0], mode)
        let levels = varName === 'wind_uv_80m' ? ['80m'] : Terrier.variableLevelsForStack(sources[0].name)
        let interp = interpForVariable(sources[0])
        switch (variable.dataType) {
        case 'reflectivity':
          // if (radarOnly) {
            timeRange = [-1*60*60,0.0,64]
          // } else {
          //   timeRange = [-4*60*60,4*60*60,64]
          // }
          newLayer = new Layer(ovl, 
            {'displayName': variable.name,
            'layerName': variable.name,
            'sources': sources,
            'levels': levels,
            'units': 'dBz',
            'colorsGrey': colorMap,
            'colors': colorMap,
            'importanceScale': 16.0,
            'timeRange': timeRange,
            // The load callback lets us insert some logic when the manifest for a
            //  given data source loads.  You'll see more than one data source, depending
            //  on what you're displaying.
            // In this case we want to snap the displayed time range to the available
            //  data (first and last frame of radar) and then we want to snap current
            //  time to the last frame.
            'loadCallback': (manifest) => {
              // if (radarOnly) {
                // Ignore everything but the biggest region
                if (manifest.region != 'conus') {
                  return
                }

                // The manifest has a list of time slices which we can interrogate
                let firstSlice = manifest.timeSlices[0]
                let lastSlice = manifest.timeSlices.slice(-1)[0]

                // Construct a new relative time range to display
                // Snap to the available time slices
                let newTimeRange = [firstSlice.forecastEpoch,lastSlice.forecastEpoch]
                ovl.setTimeRange(newTimeRange[0]*1000,newTimeRange[1]*1000)
                setTimeRange(newTimeRange)

                // And snap to the end for the current time
                ovl.setCurrentTime(lastSlice.forecastEpoch)
              // }
            }
            })
          break;
          case 'visual':
            // We can use defaults in most cases to display these
            newLayer = new Layer(ovl, 
              {'displayName': variable.name,
              'layerName': variable.name,
              'sources': sources,
              'levels': levels,
              'units': variable.units,
              'colorsGrey': colorMap,
              'colors': colorMap,
              'importanceScale': 32.0,
              'timeRange': timeRange,
              'loadCallback': (manifest) => {
                let firstSlice = manifest.timeSlices[0]
                let lastSlice = manifest.timeSlices.slice(-1)[0]
                // Construct a new relative time range to display
                // Snap to the available time slices
                let newTimeRange = [firstSlice.forecastEpoch,lastSlice.forecastEpoch]
                ovl.setTimeRange(newTimeRange[0]*1000,newTimeRange[1]*1000)
                setTimeRange(newTimeRange)
                // And snap to the end for the current time
                ovl.setCurrentTime(lastSlice.forecastEpoch)
              },
              'interpMode': interp
              })                        
          break;
          default:
            // We can use defaults in most cases to display these
            newLayer = new Layer(ovl, 
              {'displayName': variable.name,
              'layerName': variable.name,
              'sources': sources,
              'levels': levels,
              'units': variable.units,
              'colorsGrey': colorMap,
              'colors': colorMap,
              'timeRange': timeRange,
              'interpMode': interp
              })                        
              break;
        }
        if (newLayer) {
          newLayers.push(newLayer);
        }
      }
    })
    console.log(newLayers)
    setLayers(newLayers)
    if (newLayers.length > 0) {
      setCurLayer(0)
      _setUnits(newLayers[0].units)    
    }

    dispatch(
      setCurLayer({
        layer: -1,
        mode: '',
      })
    )
    _setUnits(newLayers[0].units)
    // ovl.setNearestFrame(true)
    const now = Date.now() / 1000
    setCurTime(now)
  }
  // startUpFunc()
  useEffect(() => {
    startUpFunc()
  }, [map])

  const canDisplayLegend =
    legendVisible && curLayer.layer >= 0 && curLayer.layer < layers.length

  const canDisplayMediaControls =
    curTime != Number.NEGATIVE_INFINITY &&
    layers.length > 0 &&
    curLayer.layer >= 0 &&
    curLayer.layer < layers.length

  const canDisplayCanvas = curLayer.layer >= 0 && curLayer.layer < layers.length

  // const handleLeafletMapClick = (e) => {
  //   const layers = Terrier.ovl.getLayers()
  //   if (layers.length > 0) {
  //     const layer = layers[0]
  //     if (layer.name === 'visual') {
  //       return
  //     } else {
  //       const x = e.containerPoint.x
  //       const y = e.containerPoint.y
  //       const ret = layer.queryValue(x, y)
  //       if (ret === null || !ret['value']) {
  //         return
  //       } else if (layer.name === 'windUV') {
  //         ret['value'] = [
  //           Math.sqrt(
  //             Math.pow(ret['value'][0], 2) + Math.pow(ret['value'][1], 2)
  //           ),
  //         ]
  //       }
  //       let value = convertValuesToTruweatherUnits(
  //         ret['value'][0],
  //         LayerToUnitsMapping[layer.name]
  //       )
  //       value = formatValue(value, layer.name)
  //       new L.Popup()
  //         .setContent(
  //           `<div className=''>Location : ${
  //             e.latlng.lat.toFixed(5) + ', ' + e.latlng.lng.toFixed(5)
  //           }</div>
  //           <div>Value : ${
  //             value.toString() + ' ' + LayerToUnitsMapping[layer.name]
  //           }</div>`
  //         )
  //         .setLatLng(e.latlng)
  //         .openOn(map)
  //     }
  //   }
  // }

  return (
    <div className={'w-full h-full relative'}>
      {canDisplayMediaControls && (
        <div
          className={
            'w-2/3 absolute left-1/2 right-1/2 -translate-x-1/2 bottom-10 h-16 z-[99999998]'
          }
        >
          {canDisplayMediaControls && (
            <MediaControls
              curTime={displayedTime}
              setCurTime={setCurTime}
              isPlaying={isPlaying}
              timeRange={timeRange}
              setIsPlaying={setIsPlaying}
            />
          )}
        </div>
      )}
      {canDisplayLegend && curLayer.layer === 1 && (
        <img
          className="w-56 h-10 flex flex-col text-center items-center justify-center p-2 z-[9999]
        absolute right-0 top-0"
          src={require('../../assets/my_radar_legend.png')}
          alt={'my radar legend'}
        />
      )}
      {canDisplayLegend && curLayer.layer !== 1 && (
        <Legend
          colorMap={layers[curLayer.layer].getColorMap()}
          units={layers[curLayer.layer].getUnits()}
        />
      )}
      {canDisplayCanvas && <canvas ref={canvasRef}></canvas>}
    </div>
  )
}

export default WetDogWeatherLayers
