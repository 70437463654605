//eslint-disable-next-line @typescript-eslint/no-explicit-any
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { TWS_API_URL } from '../../../environment/apis.config'
import { timeouts } from '../../../services/RequestTimeouts'
import { getAccessToken } from '../../../utils/auth.util'

interface WaypointWithAltitude {
  latDeg: number
  lonDeg: number
  altFtAGL: number
}

/**
 * 
 * @param x1 Waypoint 1 lat
 * @param y1 Waypoint 1 long
 * @param x2 Waypoint 2 lat
 * @param y2 Waypoint 2 long
 * @returns 
 * 
 * For 2 waypoints, we are dividing the waypoints into 4 
 * different waypoints for gradual increase in altitude.
 * First and last are 10m. In between are 80m
 */
const divide2Waypoints = (x1: number, y1: number, x2: number, y2: number) => {
  function getWaypointAt(p: number, alt: number) {
      return {
        latDeg: x1 + p * (x2 - x1),
        lonDeg: y1 + p * (y2 - y1),
        altFtAGL: alt,
      };
  }

  return [
    getWaypointAt(0, 10 * 3.28),    // 0% (Start point)
    getWaypointAt(0.1, 80 * 3.28),  // 10% of the distance
    getWaypointAt(0.9, 80 * 3.28),  // 90% of the distance
    getWaypointAt(1, 10 * 3.28)     // 100% (End point)
  ];
}

/**
 * 
 * @param waypoints 
 * @returns waypoints
 * 
 * For greater than 3 waypoints, we are hardcoding the 
 * altitude for the first and the last waypoint to 10m
 * The inbetween waypoints will be set to 80m
 * TODO: Make this altitude value editable via UI input
 */
const modifyGt3Waypoints = (waypoints: WaypointWithAltitude[]) => {
  waypoints.forEach((waypoint, i) => {
    if (i == 0 || i == waypoints.length - 1) {
      waypoint.altFtAGL = 10 * 3.28;
    } else {
      waypoint.altFtAGL = 80 * 3.28;
    }
  })

  return waypoints;
}

export const parseWaypoints = (waypoints: string): WaypointWithAltitude[] => {
  const wsplit = waypoints.split(',')

  let waypointsWithAltitude: WaypointWithAltitude[] = []
  for (let i = 0; i < wsplit.length; i += 3) {
    const latDeg = parseFloat(wsplit[i])
    const lonDeg = parseFloat(wsplit[i + 1])
    const altValue = parseFloat(wsplit[i + 2])
    const altFtAGL = isNaN(altValue) ? 0 : altValue * 3.28

    waypointsWithAltitude.push({
      latDeg,
      lonDeg,
      altFtAGL,
    })
  }

  const noOfWaypoints = waypointsWithAltitude.length;

  if (noOfWaypoints === 2) {
    waypointsWithAltitude = divide2Waypoints(
      waypointsWithAltitude[0].latDeg,
      waypointsWithAltitude[0].lonDeg,
      waypointsWithAltitude[1].latDeg,
      waypointsWithAltitude[1].lonDeg,
    );
  } else {
    waypointsWithAltitude = modifyGt3Waypoints(waypointsWithAltitude);
  }

  return waypointsWithAltitude
}

const timeoutSignal = (timeout: number) => {
  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  return { signal: controller.signal, clear: () => clearTimeout(id) }
}

export const fetchEval = createAsyncThunk(
  'routecast/fetchEval',
  async (requestBody: any, thunkAPI) => {
    const { signal, clear } = timeoutSignal(timeouts.routecast)
      const token = await getAccessToken();
      const response = await fetch(TWS_API_URL + '/routecast/evaluate-json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
        signal, // aborts the request if timeout is reached
      })
      clear() // Clear the timeout if the request was successful
      if (!response.ok) {
        const errorData = await response.json()
        return thunkAPI.rejectWithValue(errorData)
      }

      const responseData = await response.json()
      return responseData

  }
)

export const fetchOpt = createAsyncThunk(
  'routecast/fetchOpt',
  async (requestBody: any, thunkAPI) => {
    const { signal, clear } = timeoutSignal(timeouts.routecast)
    try {
      const token = await getAccessToken();
      const optResp = await fetch(TWS_API_URL + '/routecast/optimize-json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
        signal, // aborts the request if timeout is reached
      })
      clear() // Clear the timeout if the request was successful

      if (!optResp.ok) {
        const errorData = await optResp.json()
        return thunkAPI.rejectWithValue(errorData)
      }

      const optRespData = await optResp.json()
      return optRespData
    } catch (error) {
      clear() // Clear the timeout if the request was unsuccessful too.
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const saveRouteApi = createAsyncThunk(
  '/routecast/save',
  async (requestBody: any, thunkAPI) => {
    const { signal, clear } = timeoutSignal(timeouts.routecast)

    try {
      const token = await getAccessToken();
      const optResp = await fetch(TWS_API_URL + '/routes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
        signal, // aborts the request if timeout is reached
      })
      clear() // Clear the timeout if the request was successful

      if (!optResp.ok) {
        const errorData = await optResp.json()
        return thunkAPI.rejectWithValue(errorData)
      }

      const optRespData = await optResp.json()
      return optRespData
    } catch (error) {
      clear() // Clear the timeout if the request was unsuccessful too.
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const initNewRoute = {
  waypoints: '',
  evalApiResponse: null,
  optApiResponse: null,
  evalApiError: null,
  optApiError: null,
  dropDown: false,
  startDate: null,
  drawnPath: null,
}

const routecastSlice = createSlice({
  name: 'routecast',
  initialState: {
    routes: [
      {
        waypoints: '',
        evalApiResponse: null,
        optApiResponse: null,
        evalApiError: null,
        optApiError: null,
        startDate: null,
        drawnPath: null,
      },
    ],
    showRouteCAST: false,
    editRoutecast: true,
    selectedIndex: 0,
    dropDown: false,
  },
  reducers: {
    setWaypoints: (state, action: PayloadAction<string>) => {
      state.routes[state.selectedIndex].waypoints = action.payload
    },
    setEvalApiResponse: (state, action: PayloadAction<any>) => {
      state.routes[state.selectedIndex].evalApiResponse = action.payload
    },
    setOptApiResponse: (state, action: PayloadAction<any>) => {
      state.routes[state.selectedIndex].optApiResponse = action.payload
    },
    setDropDown: (state, action) => {
      state.dropDown = action.payload
    },
    clearEvalApiResponse: (state) => {
      state.routes[state.selectedIndex].evalApiResponse = null
    },
    setStartDate: (state, action) => {
      state.routes[state.selectedIndex].startDate = action.payload
    },
    clearStartDate: (state) => {
      state.routes[state.selectedIndex].evalApiResponse = null
    },
    setDrawnPolyline: (state, action: PayloadAction<any>) => {
      state.routes[state.selectedIndex].drawnPath = action.payload
    },
    toggleRouteCast: (state, action: PayloadAction<boolean>) => {
      state.showRouteCAST = action.payload
    },
    routeCastEdit: (state, action: PayloadAction<boolean>) => {
      state.editRoutecast = action.payload
    },
    setSelectedIndex: (state, action: PayloadAction<number>) => {
      state.selectedIndex = action.payload
    },
    incrementRoute: (state) => {
      state.routes = [...state.routes, initNewRoute]
      state.selectedIndex += 1
    },
    decrementRouteArray: (state) => {
      //before trying to refactor this, check comment in RouteCAST.tsx deleteCurrentRoute fn
      if (state.routes.length === 3) {
        if (state.selectedIndex === 0) {
          state.routes = [state.routes[1], state.routes[2]]
        } else if (state.selectedIndex == 1) {
          state.routes = [state.routes[0], state.routes[2]]
        } else {
          state.routes = [state.routes[0], state.routes[1]]
        }
      } else if (state.routes.length === 2) {
        if (state.selectedIndex === 0) {
          state.routes = [state.routes[1]]
        } else {
          state.routes = [state.routes[0]]
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEval.fulfilled, (state, action) => {
        state.routes[state.selectedIndex].evalApiResponse = action.payload
        state.routes[state.selectedIndex].evalApiError = null // Clear error on successful response
      })
      .addCase(fetchEval.rejected, (state, action) => {
        state.routes[state.selectedIndex].evalApiError =
          action.payload || 'Unknown error during evaluation'
      })
  },
})

export const {
  setWaypoints,
  setEvalApiResponse,
  setOptApiResponse,
  setDropDown,
  clearEvalApiResponse,
  setStartDate,
  clearStartDate,
  setDrawnPolyline,
  toggleRouteCast,
  routeCastEdit,
  incrementRoute,
  setSelectedIndex,
  decrementRouteArray,
} = routecastSlice.actions
export default routecastSlice.reducer
